import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import Client from "./views/Client";
import Admin from "./views/Admin";

function App() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Routes>
        <Route path={"/"} element={<Client />} />
        <Route path={"/cafe/elida"} element={<Admin />} />
      </Routes>
    </Router>
  );
}

export default App;
