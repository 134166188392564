import Category from "components/Category";
import Products from "components/Products";
import React, { useEffect, useState } from "react";

const ServiceType = ({ serviceType, handleServiceTypeSelect, type }) => (
  <div
    style={{
      backgroundColor: serviceType === type ? "#0071B9" : "transparent",
      border: "1px solid #d7d7d7",
      color: serviceType === type ? "#FFF" : "#000",
    }}
    className={`px-8 py-3 rounded-[10px] w-auto cursor-pointer`}
    onClick={() => handleServiceTypeSelect(type)}
  >
    <p className="text-[18px] text-center">{type}</p>
  </div>
);

const Admin = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);

  const [serviceType, setServiceType] = useState("");
  const handleServiceTypeSelect = (type) => setServiceType(type);

  useEffect(() => {
    const promptPassword = () => {
      const password = prompt("Please enter the password:");

      if (password) {
        if (password === "eis") {
          setAuthenticated(true);
          setShowOverlay(false);
        } else {
          alert("Incorrect password. Please try again.");
          promptPassword();
        }
      } else {
        alert("Password entry canceled.");
        setShowOverlay(false);
      }
    };

    if (!authenticated && showOverlay) {
      promptPassword();
    }
  }, [authenticated, showOverlay]);

  if (authenticated) {
    return (
      <div className="p-3 pb-14 sm:p-10">
        <h1 className="text-[30px] text-center font-bold">Dienstleistungen</h1>

        <div className="flex item-center gap-3 flex-wrap w-full mt-5">
          <ServiceType
            type="Kategorien"
            serviceType={serviceType}
            handleServiceTypeSelect={handleServiceTypeSelect}
          />

          <ServiceType
            type="Produkte"
            serviceType={serviceType}
            handleServiceTypeSelect={handleServiceTypeSelect}
          />
        </div>

        {serviceType === "Kategorien" && <Category />}
        {serviceType === "Produkte" && <Products />}
      </div>
    );
  }

  return null;
};

export default Admin;
