import React, { useEffect, useState } from "react";
import { HiMinus, HiOutlinePlus } from "react-icons/hi";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
// import data from "./data.json";
import "../tailwind.css";
import Sheet from "react-modal-sheet";
import { HiOutlineArrowRight } from "react-icons/hi2";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";

function amountCommaSeparator(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function App() {
  const [data, setData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(
    Array(data.length).fill(false)
  );
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [productsQuantity, setProductsQuantity] = useState({});
  const [totalSelectedPrice, setTotalSelectedPrice] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productPrice, setProductPrice] = useState(0);

  useEffect(() => {
    axios
      .get("/client/all")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log("client-error", error);
      });
  }, []);

  const getProductById = (productId) => {
    for (const category of filteredData) {
      const foundProduct = category.items.find(
        (product) => product.id === productId
      );
      if (foundProduct) {
        return foundProduct;
      }
    }
    return null;
  };

  const handleQuantityChange = (productId, change) => {
    const updatedQuantity = { ...productsQuantity };
    updatedQuantity[productId] = (updatedQuantity[productId] || 0) + change;

    // Ensure the quantity doesn't go below zero
    if (updatedQuantity[productId] < 0) {
      updatedQuantity[productId] = 0;
    }

    setProductsQuantity(updatedQuantity);

    const product = getProductById(productId);
    if (product) {
      const selectedProductIndex = selectedProducts.findIndex(
        (item) => item.id === productId
      );

      if (selectedProductIndex !== -1) {
        const updatedSelectedProducts = [...selectedProducts];
        updatedSelectedProducts[selectedProductIndex].quantity =
          updatedQuantity[productId];

        // Remove the product if the quantity becomes 0
        if (updatedQuantity[productId] === 0) {
          updatedSelectedProducts.splice(selectedProductIndex, 1);
        }

        setSelectedProducts(updatedSelectedProducts);

        // Update total price accordingly
        const priceChange = (productPrice[productId] || product.price) * change;
        setTotalSelectedPrice(
          (prevTotalSelectedPrice) => prevTotalSelectedPrice + priceChange
        );
      } else {
        setSelectedProducts([
          ...selectedProducts,
          {
            id: productId,
            quantity: updatedQuantity[productId],
            name: product.name,
            price: productPrice[productId] || product.price,
          },
        ]);

        // Update total price accordingly
        const priceChange = (productPrice[productId] || product.price) * change;
        setTotalSelectedPrice(
          (prevTotalSelectedPrice) => prevTotalSelectedPrice + priceChange
        );
      }
    }
  };

  const selectedCategoryData = selectedCategories.map((cat) =>
    data.find((item) => item.category === cat)
  );
  const unselectedCategoryData = data.filter(
    (item) => !selectedCategories.includes(item.category)
  );
  const reorderedData = [
    ...selectedCategoryData.reverse(),
    ...unselectedCategoryData,
  ];

  const filteredData = reorderedData.filter((item) =>
    selectedCategories.length > 0
      ? selectedCategories.includes(item.category)
      : true
  );

  const filteredProducts = filteredData.filter((category) =>
    category.items.some((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const toggleAccordion = (index) => {
    const newAccordionOpen = [...accordionOpen];
    newAccordionOpen[index] = !newAccordionOpen[index];
    setAccordionOpen(newAccordionOpen);
  };

  const handleCategorySelect = (category) => {
    if (selectedCategories.includes(category)) {
      setSearchTerm("");
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSearchTerm("");
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const isCategorySelected = (category) => {
    return selectedCategories.includes(category);
  };

  const filterProductName = (productName) => {
    const startIndex = productName
      .toLowerCase()
      .indexOf(searchTerm.toLowerCase());
    if (startIndex !== -1) {
      const beforeMatch = productName.substring(0, startIndex);
      const match = productName.substring(
        startIndex,
        startIndex + searchTerm.length
      );
      const afterMatch = productName.substring(startIndex + searchTerm.length);
      return (
        <>
          {beforeMatch}
          <span className="font-bold text-[20px] text-primaryBlue">
            {match}
          </span>
          {afterMatch}
        </>
      );
    }
    return productName;
  };

  const handleDeleteProduct = (productId) => {
    // Filter out the product with the given productId
    const updatedSelectedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );

    // Update the selectedProducts state
    setSelectedProducts(updatedSelectedProducts);

    // Update total price accordingly
    const deletedProduct = selectedProducts.find(
      (product) => product.id === productId
    );
    if (deletedProduct) {
      const priceChange = deletedProduct.price * deletedProduct.quantity;
      setTotalSelectedPrice(
        (prevTotalSelectedPrice) => prevTotalSelectedPrice - priceChange
      );
    }

    // Update productsQuantity if the product was deleted
    const updatedQuantity = { ...productsQuantity };
    delete updatedQuantity[productId];
    setProductsQuantity(updatedQuantity);
  };

  const toggleAccordionAll = () => {
    const allOpen = accordionOpen.every((open) => open);
    if (allOpen) {
      setAccordionOpen(Array(filteredData.length).fill(false));
    } else {
      setAccordionOpen(Array(filteredData.length).fill(true));
    }
  };

  const clearAllCategories = () => {
    setProductsQuantity({});
    setTotalSelectedPrice(0);
    setSelectedProducts([]);
  };

  return (
    <div className="container mx-auto py-5 px-3">
      {/* SEARCH */}
      <div className="relative">
        <input
          className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
          placeholder="Produkte suchen..."
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm !== "" ? (
          <IoMdClose
            onClick={() => setSearchTerm("")}
            fontSize={25}
            className="absolute top-0 right-0 mt-3 mr-4 cursor-pointer"
          />
        ) : (
          <FiSearch
            fontSize={25}
            className="absolute top-0 right-0 mt-3 mr-4"
          />
        )}
      </div>
      {/* SELECT CATEGORIES */}
      <div className="flex item-center gap-3 flex-wrap w-full mt-5">
        {data.map((category, i) => (
          <div
            style={{
              backgroundColor: isCategorySelected(category.category)
                ? "#0071B9"
                : "white",
              border: `1px solid ${
                isCategorySelected(category.category) ? "#0071B9" : "#d7d7d7"
              }`,
              color: isCategorySelected(category.category) ? "white" : "black",
            }}
            key={i}
            className={`py-2 px-5 rounded-[10px] w-auto cursor-pointer`}
            onClick={() => handleCategorySelect(category.category)}
          >
            <p className="text-[18px] text-center">{category.category}</p>
          </div>
        ))}
        {selectedCategories.length > 1 && (
          <button
            className={`py-3 px-4 bg-primaryBlack rounded-[10px] w-auto cursor-pointer text-white border`}
            onClick={() => setSelectedCategories([])}
          >
            <p className="text-[16px] text-center">Alles löschen</p>
          </button>
        )}
      </div>
      {/* BUTTONS FOR OPEN/CLOSE ALL ACCORDIONS */}
      <div className="flex items-center gap-2 mt-5 justify-end">
        {/* <p className="text-[18px]">Categories & Products</p> */}
        {selectedProducts.length >= 1 && (
          <button
            className={`py-3 px-4 bg-white text-primaryBlack border rounded-[10px] w-auto cursor-pointer`}
            onClick={clearAllCategories}
          >
            Alles löschen
          </button>
        )}
        <button
          className="py-3 px-4 bg-primaryBlack rounded-[10px] w-auto cursor-pointer text-white border"
          onClick={toggleAccordionAll}
        >
          {accordionOpen.every((open) => open) ? "Schließen" : "Öffnen"}
        </button>
      </div>
      {/* ITEMS */}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-5 mt-5 mb-[120px]">
        {filteredProducts.map((item, i) => (
          <div key={item.id}>
            <div
              onClick={() => toggleAccordion(i)}
              className={`relative flex items-center justify-between bg-gray w-full rounded-[10px] cursor-pointer p-3.5 ${
                accordionOpen[i]
                  ? "bg-white border-b-0 rounded-bl-none rounded-br-none border border-[#d7d7d7]"
                  : ""
              }`}
            >
              <div />
              <p className="text-[18px]">{item.category}</p>
              {accordionOpen[i] ? (
                <HiMinus fontSize={22} />
              ) : (
                <HiOutlinePlus fontSize={22} />
              )}
            </div>

            {accordionOpen[i] && (
              <div
                className={`border rounded border-[#d7d7d7] px-2.5 py-5  ${
                  accordionOpen[i]
                    ? "border-t-0 rounded-tl-none rounded-tr-none"
                    : ""
                }`}
              >
                {item.items?.map((product, j) => {
                  return (
                    <div
                      key={j}
                      className="flex items-center justify-between py-5 px-4 border-b-2 border-[#EEE]"
                    >
                      <p className="text-[16px]">
                        {filterProductName(product.name)}
                      </p>
                      {product.price && (
                        <div className="flex items-center gap-5">
                          <div className="flex items-center gap-3 bg-gray py-4 px-4 rounded-[10px]">
                            <button
                              onClick={() => {
                                handleQuantityChange(product.id, -1);
                              }}
                              disabled={productsQuantity[product.id] <= 0}
                            >
                              <HiMinus fontSize={20} />
                            </button>
                            <p className="text-[16px] mx-3">
                              {productsQuantity[product.id] || 0}
                            </p>
                            <button
                              onClick={() =>
                                handleQuantityChange(product.id, 1)
                              }
                            >
                              <HiOutlinePlus fontSize={20} />
                            </button>
                          </div>
                          <p className="font-bold text-[16px] text-right">
                            {amountCommaSeparator(product.price)} €
                          </p>
                        </div>
                      )}
                      {product?.prices?.grob && product?.prices?.klein && (
                        <div className="flex items-center gap-3">
                          {productPrice[product.id] !== undefined && (
                            <div className="flex items-center gap-3 bg-gray py-4 px-4 rounded-[10px]">
                              <button
                                onClick={() => {
                                  handleQuantityChange(product.id, -1);
                                }}
                                disabled={productsQuantity[product.id] <= 0}
                              >
                                <HiMinus fontSize={20} />
                              </button>
                              <p className="text-[16px] mx-3">
                                {productsQuantity[product.id] || 0}
                              </p>
                              <button
                                onClick={() =>
                                  handleQuantityChange(product.id, 1)
                                }
                              >
                                <HiOutlinePlus fontSize={20} />
                              </button>
                            </div>
                          )}
                          <select
                            value={productPrice[product.id] || ""}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setProductPrice((prevState) => ({
                                ...prevState,
                                [product.id]:
                                  selectedValue === ""
                                    ? undefined
                                    : selectedValue,
                              }));
                            }}
                          >
                            <option value="">Size</option>
                            {product?.prices && product.prices.grob && (
                              <option value={product.prices.grob}>GR</option>
                            )}
                            {product?.prices && product.prices.klein && (
                              <option value={product.prices.klein}>KL</option>
                            )}
                          </select>
                          {productPrice[product.id] !== undefined && (
                            <p className="font-bold text-[16px] text-right">
                              {productPrice[product.id]} €
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
      {/* TOTAL PRICE */}
      <button
        onClick={() => setOpen(true)}
        className={`flex items-center justify-between fixed bottom-0 right-0 py-10 px-14 w-full bg-[#00b9a8] cursor-pointer text-white border-color-primaryBlack`}
      >
        <p className="text-[25px]">
          Gesamtpreis: {amountCommaSeparator(totalSelectedPrice)} €
        </p>
        <div className="total-price-icon">
          <HiOutlineArrowRight fontSize={30} color="white" />
        </div>
      </button>

      <Sheet isOpen={open} onClose={() => setOpen(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="container mx-auto py-5 px-3 relative flex flex-col">
              <div className="flex items-center justify-between mt-5">
                <p className="text-[30px]">
                  Gesamtpreis:{" "}
                  <strong>{amountCommaSeparator(totalSelectedPrice)} €</strong>
                </p>
                <IoMdClose
                  className="cursor-pointer"
                  fontSize={50}
                  onClick={() => setOpen(false)}
                />
              </div>
              {selectedProducts.length >= 1 ? (
                <div className="mt-5">
                  <p className="text-[35px]">Ausgewählte Produkte</p>
                  <div className="overflow-y-scroll h-[500px]">
                    {selectedProducts.map((product) => (
                      <div
                        className="flex items-center justify-between py-5 px-4 border-b-2 border-[#EEE]"
                        key={product.id}
                      >
                        <div>
                          <p className="text-[18px]">{product.name}</p>
                          <p className="text-[18px]">
                            Size: <strong>GR</strong>
                          </p>
                        </div>

                        <div className="flex items-center gap-5">
                          <div className="flex items-center gap-3 bg-gray py-3.5 px-3 rounded-[10px] mb-2">
                            <button
                              onClick={() => {
                                handleQuantityChange(product.id, -1);
                              }}
                              disabled={product.quantity <= 0}
                            >
                              <HiMinus />
                            </button>
                            <p className="mx-2 text-[18px]">
                              {product.quantity}
                            </p>
                            <button
                              onClick={() =>
                                handleQuantityChange(product.id, 1)
                              }
                            >
                              <HiOutlinePlus />
                            </button>
                          </div>
                          <p className="text-[18px]">
                            {amountCommaSeparator(product.price)} €
                          </p>
                          <AiOutlineDelete
                            size={24}
                            color="#FF4949"
                            className="cursor-pointer"
                            onClick={() => handleDeleteProduct(product.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {/* <p className="text-[35px]">
                Gesamtpreis:{" "}
                <strong>{amountCommaSeparator(totalSelectedPrice)} €</strong>
              </p> */}
              {selectedProducts.length >= 1 && (
                <button
                  className={`py-3 px-4 rounded-[10px] w-full cursor-pointer bg-white text-primaryBlack border mt-10`}
                  onClick={clearAllCategories}
                >
                  Alles löschen
                </button>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
}

export default App;
