import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { VscEdit } from "react-icons/vsc";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryEdit, setCategoryEditId] = useState(null);
  const [updateCategory, setUpdateCategory] = useState(0);
  const [error, setErrors] = useState("");

  const clearAll = () => {
    setCategory("");
    setCategoryEditId(null);
    setErrors("");
  };

  useEffect(() => {
    axios.get("/categories").then((res) => {
      setCategories(res.data);
    });
  }, [updateCategory]);

  function createCategory() {
    axios
      .post("/categories", {
        category: category,
      })
      .then(() => {
        setUpdateCategory(updateCategory + 1);
        setCategory("");
      })
      .catch((error) => {
        setErrors(error?.response?.data?.error);
      });
  }

  function editCategory() {
    axios
      .put(`/categories/${categoryEdit}`, {
        category: category,
      })
      .then(() => {
        setUpdateCategory(updateCategory + 1);
        setCategory("");
        setCategoryEditId(null);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.error);
      });
  }

  const deleteCategory = (id) => {
    axios
      .delete(`/categories/${id}`)
      .then(() => {
        setUpdateCategory(updateCategory + 1);
        setCategory("");
        setCategoryEditId(null);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (category === "") {
      setCategoryEditId(null);
    }
  }, [category]);

  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setErrors("");
      }, 3000);
    }
  }, [error]);

  return (
    <React.Fragment>
      <div
        className={`p-5 rounded-[10px] w-auto cursor-pointer bg-transparent text-black border border-[#d7d7d7] mt-5`}
      >
        <div className="flex items-center justify-between mb-3">
          <p className="text-[20px] text-[#00b9a8]">
            {categoryEdit !== null
              ? "Kategorie bearbeiten"
              : "Neue Kategorie hinzufügen"}
          </p>
          <p className="text-[18px]" onClick={clearAll}>
            Alle Felder leeren
          </p>
        </div>
        <div className="flex items-center gap-3 flex-wrap">
          <input
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Hier eingeben"
            className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
          />
          <button
            disabled={category === ""}
            className={`py-3 px-4 w-[150px] bg-white text-primaryBlack border rounded-[10px] ${
              category !== "" ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={categoryEdit !== null ? editCategory : createCategory}
          >
            {categoryEdit !== null ? "Bearbeiten" : "Erstellen"}
          </button>
        </div>
      </div>

      {error && (
        <p className="text-[24px] text-left mb-3 mt-3 text-[red]">{error}</p>
      )}

      <div
        className={`p-5 rounded-[10px] w-auto cursor-pointer bg-transparent text-black border border-[#d7d7d7] mt-5`}
      >
        <p className="text-[20px] text-left mb-3 text-[#00b9a8]">
          Kategorienliste
        </p>

        {categories?.map((c, i) => {
          return (
            <div
              className="flex item-center w-full mt-5 justify-between border-b border-[#EEE] pb-3"
              key={i}
            >
              <p className="text-[18px] text-center">{c.category}</p>
              <div className="flex items-center gap-5">
                <VscEdit
                  size={22}
                  color="#000"
                  className="cursor-pointer"
                  onClick={() => {
                    setCategoryEditId(c.id);
                    setCategory(c.category);
                  }}
                />
                <AiOutlineDelete
                  size={24}
                  color="#FF4949"
                  className="cursor-pointer"
                  onClick={() => deleteCategory(c.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Category;
