import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { VscEdit } from "react-icons/vsc";

function amountCommaSeparator(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const DisplayField = ({ text, span }) => (
  <p className="text-[17px]">
    <span className="font-bold">{span}</span>: {text}
  </p>
);

const Products = () => {
  const [keyword, setKeyword] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: "",
    categoryId: 0,
    hasSizes: false,
    sizes: {
      grob: "",
      klein: "",
    },
    price: "",
  });
  const [productEdit, setProductEditId] = useState(null);
  const [updateProduct, setUpdateProduct] = useState(0);
  const [error, setErrors] = useState("");

  const clearAll = () => {
    setNewProduct({
      name: "",
      categoryId: 0,
      hasSizes: false,
      sizes: {
        grob: "",
        klein: "",
      },
      price: "",
    });
    setProductEditId(null);
    setErrors("");
  };

  useEffect(() => {
    axios.get("/categories").then((res) => {
      setCategories(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get("/products").then((res) => {
      setProducts(res.data);
    });
  }, [updateProduct]);

  function createProduct() {
    axios.post("/products", newProduct).then(() => {
      setUpdateProduct(updateProduct + 1);
      setNewProduct({
        name: "",
        categoryId: 0,
        hasSizes: false,
        sizes: {
          grob: "",
          klein: "",
        },
        price: "",
      });
    });
  }

  function editProduct() {
    axios.put(`/products/${productEdit}`, newProduct).then(() => {
      setUpdateProduct(updateProduct + 1);
      setNewProduct({
        name: "",
        categoryId: 0,
        hasSizes: false,
        sizes: {
          grob: "",
          klein: "",
        },
        price: "",
      });
      setProductEditId(null);
    });
  }

  const deleteProduct = (id) => {
    axios
      .delete(`/products/${id}`)
      .then(() => {
        setUpdateProduct(updateProduct + 1);
        setNewProduct({
          name: "",
          categoryId: 0,
          hasSizes: false,
          sizes: {
            grob: "",
            klein: "",
          },
          price: "",
        });
        setProductEditId(null);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (productEdit !== null) {
      const productToEdit = products.find((p) => p.id === productEdit);
      if (productToEdit) {
        setNewProduct({
          name: productToEdit.name,
          categoryId: productToEdit.categoryId,
          hasSizes: productToEdit.hasSizes,
          sizes: {
            grob: productToEdit.sizes.grob,
            klein: productToEdit.sizes.klein,
          },
          price: productToEdit.price,
        });
      }
    }
  }, [productEdit, products]);

  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setErrors("");
      }, 3000);
    }
  }, [error]);

  const filterProducts = () => {
    const filtered = products.filter((product) => {
      const nameMatch = product.name
        .toLowerCase()
        .includes(keyword.toLowerCase());
      const categoryMatch = product.Category?.category
        .toLowerCase()
        .includes(keyword.toLowerCase());
      return nameMatch || categoryMatch;
    });

    return filtered.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  };

  return (
    <React.Fragment>
      <div
        className={`p-5 rounded-[10px] w-auto cursor-pointer bg-transparent text-black border border-[#d7d7d7] mt-5`}
      >
        <div className="flex items-center justify-between mb-3">
          <p className="text-[20px] text-[#00b9a8]">
            {productEdit !== null
              ? "Produkt bearbeiten"
              : "Neue Produkt hinzufügen"}
          </p>
          <p className="text-[18px]" onClick={clearAll}>
            Alle Felder leeren
          </p>
        </div>
        <div className="flex items-center gap-3 flex-wrap">
          <input
            value={newProduct.name}
            onChange={(e) =>
              setNewProduct({ ...newProduct, name: e.target.value })
            }
            placeholder="Produktname eingeben"
            className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
          />
          <select
            className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
            value={newProduct.categoryId}
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                categoryId: parseInt(e.target.value),
              })
            }
          >
            <option value="">Kategorie auswählen</option>
            {categories?.map((c, i) => (
              <option key={i} value={c.id}>
                {c.category}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-3 mt-3 flex-wrap">
          <input
            className="w-[17px] h-[17px]"
            value={newProduct.hasSizes}
            checked={newProduct.hasSizes}
            type="checkbox"
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                price: null,
                hasSizes: !newProduct.hasSizes,
              })
            }
          />
          <label className="text-[18px]">Hat Größen</label>
        </div>

        {!newProduct.hasSizes && (
          <div className="flex items-center gap-3 mt-3">
            <input
              value={newProduct.price}
              onChange={(e) =>
                setNewProduct({ ...newProduct, price: e.target.value })
              }
              type="number"
              placeholder="Produktpreis eingeben"
              className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
            />
          </div>
        )}

        {newProduct.hasSizes && (
          <div className="flex items-center gap-3 mt-3 flex-wrap">
            <input
              value={newProduct.sizes.grob}
              onChange={(e) =>
                setNewProduct({
                  ...newProduct,
                  sizes: { grob: e.target.value, klein: newProduct.sizes.grob },
                })
              }
              type="number"
              placeholder="Groben Produktpreis eingeben"
              className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
            />
            <input
              value={newProduct.sizes.klein}
              onChange={(e) =>
                setNewProduct({
                  ...newProduct,
                  sizes: { klein: e.target.value, grob: newProduct.sizes.grob },
                })
              }
              type="number"
              placeholder="Kleinen Produktpreis eingeben"
              className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray"
            />
          </div>
        )}

        <button
          disabled={
            newProduct.name === "" ||
            newProduct.categoryId === 0 ||
            (newProduct.hasSizes &&
              (newProduct.sizes.grob === "" || newProduct.sizes.klein === ""))
          }
          className={`mt-3 py-3 px-4 w-[150px] bg-white text-primaryBlack border rounded-[10px] cursor-pointer`}
          onClick={productEdit !== null ? editProduct : createProduct}
        >
          {productEdit !== null ? "Bearbeiten" : "Erstellen"}
        </button>
      </div>

      <div className="relative">
        <input
          className="w-full bg-gray py-3 px-5 rounded-[10px] text-primaryBlack pr-10 text-[18px] border border-darkGray mt-5"
          placeholder="Suche nach Produktnamen und kategorie..."
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        {keyword !== "" ? (
          <IoMdClose
            onClick={() => setKeyword("")}
            fontSize={25}
            className="absolute top-[35px] right-0 mr-4 cursor-pointer"
          />
        ) : (
          <FiSearch
            fontSize={25}
            className="absolute top-[35px] right-0 mr-4"
          />
        )}
      </div>

      {error && (
        <p className="text-[24px] text-left mb-3 mt-3 text-[red]">{error}</p>
      )}

      <div
        className={`p-5 rounded-[10px] w-auto cursor-pointer bg-transparent text-black border border-[#d7d7d7] mt-5`}
      >
        <p className="text-[20px] text-left mb-3 text-[#00b9a8]">
          Produktliste
        </p>

        {filterProducts()?.map((p, i) => {
          return (
            <div
              className="flex item-center w-full mt-5 justify-between border-b border-[#EEE] pb-3"
              key={i}
            >
              <div className="flex flex-col gap-3">
                <DisplayField text={p.name} span={"Name"} />
                <DisplayField text={p.Category?.category} span={"Kategorie"} />

                {p?.hasSizes ? (
                  <DisplayField
                    text={`Grob: ${amountCommaSeparator(
                      p?.sizes.grob
                    )} € / Klein: ${amountCommaSeparator(p?.sizes.klein)} €`}
                    span={"Größen"}
                  />
                ) : (
                  <DisplayField
                    text={`${amountCommaSeparator(p.price)} €`}
                    span={"Preis"}
                  />
                )}
                <DisplayField
                  text={moment(p.createdAt).format("llll")}
                  span={"Erstellt am"}
                />
                <DisplayField
                  text={moment(p.updatedAt).format("llll")}
                  span={"Aktualisiert am"}
                />
              </div>
              <div className="flex items-center gap-5">
                <VscEdit
                  size={22}
                  color="#000"
                  className="cursor-pointer"
                  onClick={() => {
                    setProductEditId(p.id);
                    setNewProduct({
                      name: p.name,
                      categoryId: p.id,
                      hasSizes: p.hasSizes,
                      sizes: {
                        grob: p.sizes.grob,
                        klein: p.sizes.klein,
                      },
                      price: p.price,
                    });
                  }}
                />
                <AiOutlineDelete
                  size={24}
                  color="#FF4949"
                  className="cursor-pointer"
                  onClick={() => deleteProduct(p.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Products;
